import GooglePlay from "../../assets/google-play.svg"
import AppStore from "../../assets/app-store.svg"
import SuccessSVG from "../../assets/success.svg"
import { DownloadWith } from "../../components/Layout/Footer/styles"
import { isMobile, isAndroid } from "react-device-detect"
import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { QRcodeS } from "./styles"
import "./Success.style.css"
import { useSendPageView } from "../../hooks"
import { logEvent } from "firebase/analytics"
import { analytics } from "../../config"
import { AppContext } from "../../store"
import { appsflyerController } from "../../api"

export const Success = () => {
  // const { state } = useContext(RootContext) as ContextValue
  const { state } = useContext(AppContext);
  console.log(state);
  const [dynamicLink, setDynamicLink] = useState("")
  const {queryParams, onelink, ...user} = state
  console.log("user", user)
  const serializedUser = JSON.stringify(user)
  const base64EncodedUser = btoa(unescape(encodeURIComponent(serializedUser)))

  useSendPageView({
    title: "Success",
  })

  const setLink = () => {
    const link = encodeURIComponent(
      `https://lifebuddy.ai/sign-up/${base64EncodedUser}`,
    )

    const fullLink = appsflyerController.getOneLinkURL(link, onelink)
    setDynamicLink(fullLink)
  }

  useEffect(() => {
    setLink()
  }, [])

  const sendAnalyticsHandler = (platform: "ios" | "android") => {
    return () => {
      logEvent(analytics, "download_app", {
        name: platform,
      })
    }
  }

  return (
    <div className='success-block'>
      <div className='success-info'>
        <img src={SuccessSVG} alt='success' />
      </div>
      <div style={{ maxWidth: "calc(540px + 150px)" }}>
        {isMobile
          ? "In order to start your session please download the app"
          : "Please scan the QR code below with your phone camera, and download the app to your phone to start your coaching session there."}
      </div>
      <div className='download-wrapper'>
        <DownloadWith>
          {isMobile ? (
            isAndroid ? (
              <Link to={dynamicLink} onClick={sendAnalyticsHandler("android")}>
                <img src={GooglePlay} alt='google-play' />
              </Link>
            ) : (
              <Link to={dynamicLink} onClick={sendAnalyticsHandler("ios")}>
                <img src={AppStore} alt='app-store' />
              </Link>
            )
          ) : (
            <div style={{ display: "flex", gap: 100 }}>
              <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                <QRcodeS value={dynamicLink} />
                <Link
                  to='https://play.google.com/store/apps/details?id=ai.lifebuddy.app'
                  onClick={sendAnalyticsHandler("android")}
                >
                  <img src={GooglePlay} alt='google-play' />
                </Link>
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                <QRcodeS value={dynamicLink} />
                <Link
                  to='https://apps.apple.com/app/lifebuddy-ai-ai-life-coach/id6503171740'
                  onClick={sendAnalyticsHandler("ios")}
                >
                  <img src={AppStore} alt='app-store' />
                </Link>
              </div>
            </div>
          )}
        </DownloadWith>
      </div>
    </div>
  )
}

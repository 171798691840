class AppsflyerController {
  generateOneLinkURL() {
    const oneLinkURL = "https://lifebuddy.onelink.me/N4Of"
    const mediaSource = { keys: ["utm_source"], defaultValue: "Direct" }
    // const deepLinkValue = {defaultValue:"None"};
    const campaign = { keys: ["utm_campaign"], defaultValue: "None" }
    // const channel = { keys: ["utm_medium"], defaultValue: "None" }
    // const device = { keys: ["utm_device"], defaultValue: "None" }
    const googleClickIdKey = "af_sub1"
    // eslint-disable-next-line camelcase
    // const deep_link_sub1 = {paramKey:"deep_link_sub1",defaultValue:"Onboarding"};
    // eslint-disable-next-line camelcase
    // const deep_link_sub2 = { paramKey: "deep_link_sub2", keys: ["utm_campaign"], defaultValue: "None" }
    // eslint-disable-next-line camelcase
    // const deep_link_sub3 = { paramKey: "deep_link_sub3", keys: ["utm_medium"], defaultValue: "None" }
    // eslint-disable-next-line camelcase
    // const deep_link_sub4 = { paramKey: "deep_link_sub4", keys: ["utm_source"], defaultValue: "None" }
    // eslint-disable-next-line camelcase
    const deep_link_sub1 = { paramKey: "deep_link_sub1", keys: ["gclid"], defaultValue: "None" }
    // const afSub2 = {defaultValue:"Onboarding"};
    // eslint-disable-next-line camelcase
    const custom_ss_ui = { paramKey: "af_ss_ui", defaultValue: "true" }

    // Call the function after embedding the code through a global parameter on the window object called window.AF_SMART_SCRIPT.
    // Onelink URL is generated.
    if ((window as any).AF_SMART_SCRIPT) {
      const result = (window as any).AF_SMART_SCRIPT.generateOneLinkURL({
        oneLinkURL: oneLinkURL,
        afParameters: {
          mediaSource: mediaSource,
          // deepLinkValue: deepLinkValue,
          campaign: campaign,
          // channel: channel,
          // device: device,
          googleClickIdKey: googleClickIdKey,
          afCustom: [
            // eslint-disable-next-line camelcase
            deep_link_sub1,
            // eslint-disable-next-line camelcase
            // deep_link_sub2,
            // eslint-disable-next-line camelcase
            // deep_link_sub3,
            // eslint-disable-next-line camelcase
            // deep_link_sub4,
            // eslint-disable-next-line camelcase
            // deep_link_sub5,
            // eslint-disable-next-line camelcase
            custom_ss_ui,
          ],
          // afSub2: afSub2,
        },
      })

      console.log(result.clickURL)
      return result.clickURL
    }
    return false
  }

  getOneLinkURL(deeplink: string, onelink: string) {
    if (onelink !== "") {
      return `${onelink}&deep_link_value=${deeplink}&is_retargeting=true&af_reengagement_window=1h`
    } else {
      return `https://lifebuddy.onelink.me/N4Of?af_xp=custom&pid=Direct&deep_link_sub1=Homepage&af_sub2=Homepage&c=None&deep_link_value=${deeplink}&is_retargeting=true&af_reengagement_window=1h`
    }
  }
}

export const appsflyerController = new AppsflyerController